@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #e9e9e9;
}

body {
  font-family: "Inter", sans-serif;
  /* border: 2px solid red; */
  max-width: 520px;
  margin: auto;
  background-color: white;
}

input[type="checkbox"] {
  accent-color: #fe5935;
}
